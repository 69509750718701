import $ from 'jquery';
import { $window, $body } from '../utils/globals';
import className from '../utils/constant-names';

let _scroll     = 0;
let _lastScroll = 0;

const $header = $('.header');
const $firstSection = $('.main').find('> section:first-child');

$window.on( 'scroll', function() {
	_scroll = $window.scrollTop();

	$body
        .toggleClass( className.ShowFixedHeader, _scroll > 10 )
        .toggleClass(className.IsScrollTop, _lastScroll > _scroll);

	_lastScroll = _scroll;
});
