import $ from 'jquery';
import { tns } from 'tiny-slider/src/tiny-slider';

const _element   = '.js-slider-products';
const $container = $(_element);

if ( $container.length ) {
    const _prev = `
                <i class="ico-arrow ico-arrow--prev">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 222.51 179.61">
                      <path d="M222.47,90.8c-1.58,4.61-5.13,6.96-9.6,8.01-6.34,1.5-12.56,3.45-18.87,5.07-10.57,2.71-21.21,3.07-31.95,3.07-5.25,0-10.5-.07-15.74,.02-8,.13-16.09-.36-23.79-1.93-7.48-1.52-15.19-1.65-22.46-3.84-7.96-2.4-16.4-2.57-24.17-5.04-7.09-2.26-14.6-3.01-21.42-6.12-.31-.14-.78,.06-1.21,.11,5.62,6.82,11.49,13.37,16.59,20.55,4.84,6.8,9.59,13.63,14.11,20.66,5.45,8.47,11.18,16.76,16.2,25.52,1.87,3.26,3.8,6.23,3.69,10.26-.21,7.8-1.27,9.68-8.14,12.47h-1.61c-4.32-1.2-6.15-4.55-8.27-8.22-3.5-6.07-8.12-11.49-12.02-17.35-5.67-8.52-12.58-16.09-18.96-24.03-3-3.74-6.37-7.58-10.07-10.98-3.41-3.13-6.18-7.12-9.56-10.49-6.46-6.44-12.51-13.29-18.84-19.87-4.22-4.38-8.79-8.28-13.7-11.97-3.44-2.59-3.07-8.23-1.64-12.66,1.33-4.15,4.26-6.86,8.3-8.86,7.54-3.73,14.86-7.92,22.29-11.88,5.35-2.85,10.58-5.89,15.88-8.82,7.49-4.15,14.84-8.55,22.37-12.63,3.21-1.74,6.29-3.71,9.52-5.44,6.27-3.36,12.21-7.33,18.3-11.02,2.79-1.69,5.42-4.07,8.46-4.86,7.18-1.85,10.61,1.12,11.37,8.85,.75,7.57-2.94,12.64-8.61,17.21-4.71,3.79-9.8,6.95-14.77,10.27-3.98,2.66-8.77,4.09-13.04,6.36-6.31,3.35-12.33,7.29-18.77,10.38-5.82,2.79-11.23,6.29-17.01,9.12-.88,.43-2.11,.55-2.55,1.76,6.94,2.63,14.33,3.54,21.28,5.69,4.92,1.52,10.05,1.87,14.81,3.71,6.19,2.4,13.05,2.14,19.08,4.31,7.99,2.87,16.41,2.36,24.28,4.7,6.31,1.88,12.74,1.82,19.09,2.8,4.58,.71,9.25,.9,13.89,1.05,5.24,.17,10.49-.06,15.74,.06,11.33,.27,22.55-1.1,33.71-2.7,2.06-.3,4.51-1.19,6.76-1.83,5.42-1.53,8.57-.14,11.09,4.51v4.04l-.04-.02Z" />
                    </svg>
                </i>`;

    const _next = `
                <i class="ico-arrow ico-arrow--next">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 223 181">
                        <path d="M.08,88.89c1.58-4.61,5.13-6.96,9.6-8.01,6.34-1.5,12.56-3.45,18.87-5.07,10.57-2.71,21.21-3.07,31.95-3.07,5.25,0,10.5,.07,15.74-.02,8-.13,16.09,.36,23.79,1.93,7.48,1.52,15.19,1.65,22.46,3.84,7.96,2.4,16.4,2.57,24.17,5.04,7.09,2.26,14.6,3.01,21.42,6.12,.31,.14,.78-.06,1.21-.11-5.62-6.82-11.49-13.37-16.59-20.55-4.84-6.8-9.59-13.63-14.11-20.66-5.45-8.47-11.18-16.76-16.2-25.52-1.87-3.26-3.8-6.23-3.69-10.26,.21-7.8,1.27-9.68,8.14-12.47h1.61c4.32,1.2,6.15,4.55,8.27,8.22,3.5,6.07,8.12,11.49,12.02,17.35,5.67,8.52,12.58,16.09,18.96,24.03,3,3.74,6.37,7.58,10.07,10.98,3.41,3.13,6.18,7.12,9.56,10.49,6.46,6.44,12.51,13.29,18.84,19.87,4.22,4.38,8.79,8.28,13.7,11.97,3.44,2.59,3.07,8.23,1.64,12.66-1.33,4.15-4.26,6.86-8.3,8.86-7.54,3.73-14.86,7.92-22.29,11.88-5.35,2.85-10.58,5.89-15.88,8.82-7.49,4.15-14.84,8.55-22.37,12.63-3.21,1.74-6.29,3.71-9.52,5.44-6.27,3.36-12.21,7.33-18.3,11.02-2.79,1.69-5.42,4.07-8.46,4.86-7.18,1.85-10.61-1.12-11.37-8.85-.75-7.57,2.94-12.64,8.61-17.21,4.71-3.79,9.8-6.95,14.77-10.27,3.98-2.66,8.77-4.09,13.04-6.36,6.31-3.35,12.33-7.29,18.77-10.38,5.82-2.79,11.23-6.29,17.01-9.12,.88-.43,2.11-.55,2.55-1.76-6.94-2.63-14.33-3.54-21.28-5.69-4.92-1.52-10.05-1.87-14.81-3.71-6.19-2.4-13.05-2.14-19.08-4.31-7.99-2.87-16.41-2.36-24.28-4.7-6.31-1.88-12.74-1.82-19.09-2.8-4.58-.71-9.25-.9-13.89-1.05-5.24-.17-10.49,.06-15.74-.06-11.33-.27-22.55,1.1-33.71,2.7-2.06,.3-4.51,1.19-6.76,1.83-5.42,1.53-8.57,.14-11.09-4.51v-4.04Z" />
                    </svg>
                </i>`;

    let _timer   = null;
    let _timeout = 7000;

    $container.find('.js__slides').each(function(i) {
        const $this   = $(this);
        const $parent = $this.parents( 'section' );
        const $next   = $parent.next();

        const settings = {
            container: this,
            loop: true,
            startIndex: 0,
            gutter: 0,
            items: 1,
            speed: 1000,
            nav: false,
            controlsText: [_prev, _next],
            controlsPosition: 'bottom',
            mouseDrag: true
        };

        const $slider = tns( settings );

        updatePrevNextSlide( $this, 0 );

        $slider.events.on('transitionStart', function() {
            updatePrevNextSlide( $this, 1 );
            updateActiveNavInner( $this );
        });

        $slider.events.on('transitionEnd', function() {
            updatePrevNextSlide( $this, 0 );
        });

        if ( $next.hasClass( 'js-section-nav-inner' ) ) {
            $next.find( 'a' ).on( 'click', function( event ) {
                event.preventDefault();

                const $thisNav = $(this);
                const $li      = $thisNav.parent();
                const _index   = parseInt($li.index(), 10);

                $slider.goTo( _index );
            });
        }
    });

    function updateActiveNavInner( $this ) {
        const $section = $this.parents( 'section' );
        const $next    = $section.next();
        const $title   = $section.find( '.section__head h4' );

        if ( $next.hasClass( 'js-section-nav-inner' ) ) {
            const _index = parseInt($this.find( '.tns-slide-active' ).data( 'index' ), 10);

            $next
                .find( 'li' )
                .eq( _index )
                .addClass( 'current-cat' )
                .siblings()
                .removeClass( 'current-cat' );

            $title
                .find( 'span' )
                .eq( _index )
                .addClass( 'current-cat' )
                .siblings()
                .removeClass( 'current-cat' );
        }
    }

    function updatePrevNextSlide( $this, _isStart ) {
        const $parent = $this.parents( _element );
        const $active = $this.find( '.tns-slide-active' );
        const $all    = $this.find( '.slide' );
        const $prev   = $active.prev();
        const $next   = $active.next();

        $parent.toggleClass( 'is-animate', _isStart == 1 );

        $all.removeClass( 'slider-prev' );
        $all.removeClass( 'slider-next' );
        
        $prev.addClass( 'slider-prev' );
        $next.addClass( 'slider-next' );
    }
}
