import $ from 'jquery';
import { $body, $bodyTag } from '../utils/globals';
import className from '../utils/constant-names';

const _element = 'js-nav';
const $element = document.getElementsByClassName( _element )[0];
const $container = $( '.' + _element );

if ( typeof( $element ) != 'undefined' && $element != null ) {
	const $links = $element.getElementsByTagName( 'a' );

	for ( const [_index, _link] of Object.entries( $links ) ) {
		_link.onclick = function( event ) {
			if ( document.body.classList.contains ( className.IsTouch ) ) {
				const $parent = $( _link ).parent();
				const _haveChild = $parent.find('> ul').length;

				if (! $parent.hasClass( className.Hover ) && _haveChild ) {
					event.preventDefault();

					$parent
						.addClass( className.Hover )
						.siblings()
						.removeClass( className.Hover );
				}
			}
		};
	}

	$body.on('click', function(event) {
		if ( $body.hasClass( className.IsTouch ) ) {
			const $target = $( event.target );
			const isNotOnTarget = !$target.parents('.' + _element).length && !$target.hasClass(_element);

			if ( isNotOnTarget ) {
				$container
					.find( '.' + className.Hover )
					.removeClass( className.Hover );
			}
		}
	});
}
