import $ from 'jquery';
import className from '../utils/constant-names';

const _element   = '.js-section-slider-timeline';
const $container = $(_element);

if ( $container.length ) {

	$container.on( 'click', '.js__prev', function( event ) {
		event.preventDefault();

		const $this     = $(this);
		const $parent   = $this.parents( _element );
		const $active   = $parent.find( '.js__slides > .' + className.Active );
		const $prev     = ( $active.prev().length ) ? $active.prev() : $parent.find( '.js__slides > *:last-child' );
		const _index    = $prev.index();

		updateSlides( $parent, _index );
	});

	$container.on( 'click', '.js__next', function( event ) {
		event.preventDefault();

		const $this     = $(this);
		const $parent   = $this.parents( _element );
		const $active   = $parent.find( '.js__slides > .' + className.Active );
		const $prev     = ( $active.next().length ) ? $active.next() : $parent.find( '.js__slides > *:first-child' );
		const _index    = $prev.index();

		updateSlides( $parent, _index );
	});

	$container.on( 'click', '.js__timeline a', function( event ) {
		event.preventDefault();

		const $this   = $(this);
		const $parent = $this.parents( _element );
		const _index  = $this.index();

		updateSlides( $parent, _index );
	});

	function updateSlides( $parent, _index ) {
		const $slides   = $parent.find( '.js__slides > *' );
		const $images   = $parent.find( '.js__images > *' );
		const $timeline = $parent.find( '.js__timeline > *' );

		$slides
			.eq( _index )
			.addClass( className.Active )
			.siblings()
			.removeClass( className.Active );

		$timeline
			.eq( _index )
			.addClass( className.Active )
			.siblings()
			.removeClass( className.Active );

		$images
			.eq( _index )
			.addClass( className.Active )
			.siblings()
			.removeClass( className.Active );		
	}
}
