export const $style = [{
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [
        {
            "color": "#e4d9c7"
        }
    ]
},
{
    "featureType": "transit",
    "stylers": [
        {
            "color": "#cfc7b8"
        },
        {
            "visibility": "off"
        }
    ]
},
{
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
        {
            "visibility": "on"
        },
        {
            "color": "#aaa398"
        }
    ]
},
{
    "featureType": "road.highway",
    "elementType": "geometry.fill",
    "stylers": [
        {
            "color": "#c5bcad"
        }
    ]
},
{
    "featureType": "road.local",
    "elementType": "geometry.fill",
    "stylers": [
        {
            "visibility": "on"
        },
        {
            "color": "#c5bcad"
        },
        {
            "weight": 1.8
        }
    ]
},
{
    "featureType": "road.local",
    "elementType": "geometry.stroke",
    "stylers": [
        {
            "color": "#c5bcad"
        }
    ]
},
{
    "featureType": "poi",
    "elementType": "geometry.fill",
    "stylers": [
        {
            "visibility": "on"
        },
        {
            "color": "#f4e9d6"
        }
    ]
},
{
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
        {
            "color": "#a7a7a7"
        }
    ]
},
{
    "featureType": "road.arterial",
    "elementType": "geometry.fill",
    "stylers": [
        {
            "color": "#c5bcad"
        }
    ]
},
{
    "featureType": "road.arterial",
    "elementType": "geometry.fill",
    "stylers": [
        {
            "color": "#c5bcad"
        }
    ]
},
{
    "featureType": "landscape",
    "elementType": "geometry.fill",
    "stylers": [
        {
            "visibility": "on"
        },
        {
            "color": "#e3d9c7"
        }
    ]
},
{
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
        {
            "color": "#a49d90"
        }
    ]
},
{
    "featureType": "administrative",
    "elementType": "labels.text.fill",
    "stylers": [
        {
            "visibility": "on"
        },
        {
            "color": "#a49d90"
        }
    ]
},
{
    "featureType": "poi",
    "elementType": "labels.icon",
    "stylers": [
        {
            "visibility": "off"
        }
    ]
},
{
    "featureType": "poi",
    "elementType": "labels",
    "stylers": [
        {
            "visibility": "off"
        }
    ]
},
{
    "featureType": "road.arterial",
    "elementType": "geometry.stroke",
    "stylers": [
        {
            "color": "#c5bcad"
        }
    ]
},
{
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
        {
            "visibility": "off"
        }
    ]
},
{},
{
    "featureType": "poi",
    "elementType": "geometry.fill",
    "stylers": [
        {
            "color": "#f4e9d6"
        }
    ]
}
];
