/*
 * Modules
 */

import './modules/object-fit';
import './modules/add-body-class-on-page-load';
import './modules/add-fixed-header-class-on-scroll';
import './modules/add-body-class-if-is-touch';
import './modules/add-body-class-if-is-ios';
import './modules/add-body-class-if-is-ie';
import './modules/nav-is-hover-on-touch';
import './modules/btn-menu-on-click';
import './modules/init-gravity-reset';
import './modules/init-vh';
import './modules/init-header-height';
import './modules/init-aos-animations';
import './modules/init-section-slider-timeline';
import './modules/init-magnific-popup';
import './modules/init-slider-testimonials';
import './modules/init-slider-products';
import './modules/init-section-locations-intro';
import './modules/init-section-location-info';
import './modules/init-slider-main';
import './modules/init-scroll-to';
import './modules/init-our-menu';
import './modules/init-is-resize';
import './modules/open-mobile-nav';
import './modules/init-subnav-position';
import './modules/open-order-dropdown';

/*
 * Polyfills
 */

import initParallax from './polyfills/init-parallax';
import initAccordions from './polyfills/init-accordions';

initParallax();
initAccordions();
