import $ from 'jquery';

$(window).scroll(function(e){ 
    var $el = $('body.parent-pageid-374 .section-nav-inner');
    console.log( $(this).scrollTop() );
    var isPositionFixed = ($el.css('position') == 'fixed');
    if ($(this).scrollTop() > 370 && !isPositionFixed){ 
      $el.addClass('subnav-fixed');
    }
    if ($(this).scrollTop() < 370 && isPositionFixed){
      $el.removeClass('subnav-fixed');
    } 
});
