import $ from 'jquery';
import { $body, breakpoints, $window } from '../utils/globals';
import ScrollLocker from '../utils/scroll-locker';
import className from '../utils/constant-names';
import setCookie from '../utils/set-cookie';
import getCookie from '../utils/get-cookie';

const _element    = '.js-btn-menu';
const $element    = $( _element );
const _cookieName = 'is-menu-open';

$element.on( 'click', function( event ) {
	event.preventDefault();

	if ($body.hasClass(className.ShowNavMain)) {
		$body.removeClass(className.ShowNavMain);

        setCookie( _cookieName, false, 1 );

		if (!breakpoints.smallDesktopUp.matches) {
			ScrollLocker.disableScrollLock();
		}
	} else {
		$body.addClass(className.ShowNavMain);

        setCookie( _cookieName, true, 1 );

		if (!breakpoints.smallDesktopUp.matches) {
			ScrollLocker.enableScrollLock();
		}
	}
});

const _isMenuOpen = getCookie( _cookieName );

$window.on( 'load resize orientationchange', function() {
    $body.toggleClass(className.ShowNavMain, _isMenuOpen == 'true' && ! breakpoints.smallDesktop.matches );
});

