import $ from 'jquery';
import { $window } from '../utils/globals';

const initParallax = (_class = '[data-parallax="true"]') => {
	const $container = $(_class);

	if ( !$container.length ) {
		return false;
	}

	const _move = 10; // Percentage

	let _arrListTopOffset    = [];
	let _arrListBottomOffset = [];
	let _WinScroll           = 0;
	let _dimension           = [];

	$window.scroll(function(event) {
		_WinScroll = $window.scrollTop();

		Parallax();
	});

	$window.on('load resize orientationchange',function() {
		Offsets();
		setDimension();
	})

	function Offsets() {
		$container.removeAttr( 'style' );
		
		$container.each(function(i) {
			const $this   = $(this);
			const _top    = parseInt($this.offset().top - $window.height(), 10);
			const _bottom = parseInt(_top + $this.innerHeight() + $window.height(), 10);

			_arrListTopOffset[i]    = (_top < 0) ? 0 : _top;
			_arrListBottomOffset[i] = _bottom;
		});
	}

	function setDimension() {
		$container.each(function(i) {
			const $this = $(this);
			const _winHeight = parseInt($window.height(), 10);
			const _winWidth  = parseInt($window.width(), 10);
			const _position  = ( $this.data('position') != undefined ) ? $this.data('position') : 'top';

			_dimension[i] = ( $this.data('move') != undefined ) ? $this.data('move') : _move;
			_dimension[i] = ( _position == 'top' || _position == 'bottom' ) ? (_dimension[i] / 100) * _winHeight : (_dimension[i] / 100) * _winWidth;
			_dimension[i] = parseInt(_dimension[i], 10);

			if ( _position == 'top') {
				this.setAttribute( 'style', 'bottom: -' + _dimension[i] + 'px;' );
			}

			if ( _position == 'bottom') {
				this.setAttribute( 'style', 'top: -' + _dimension[i] + 'px;' );
			}

			if ( _position == 'left') {
				this.setAttribute( 'style', 'right: -' + _dimension[i] + 'px;' );
			}

			if ( _position == 'right') {
				this.setAttribute( 'style', 'left: -' + _dimension[i] + 'px;' );
			}			
		});
	}

	function Parallax() {
		$container.each(function(i) {
			if ( _WinScroll >= _arrListTopOffset[i] && _WinScroll <= _arrListBottomOffset[i]){

				const $this      = $(this);
				const _height    = _arrListBottomOffset[i] -  _arrListTopOffset[i];
				const _scroll    = _height - (_arrListBottomOffset[i] - _WinScroll);
				const _position  = ( $this.data('position') != undefined ) ? $this.data('position') : 'top';

				let _percentage = parseInt((_scroll / _height) * 100);
					_percentage = (_percentage >= 99) ? 100 : _percentage;

				if ( typeof _percentage === 'number' ) {
					const _scrollParallax = parseInt((_percentage / 100) * _dimension[i], 10);

					if ( _position == 'top') {
						this.setAttribute( 'style', 'transform: translateY(-' + _scrollParallax + 'px); bottom: -' + _dimension[i] + 'px;' );
					}

					if ( _position == 'bottom') {
						this.setAttribute( 'style', 'transform: translateY(' + _scrollParallax + 'px); top: -' + _dimension[i] + 'px;' );
					}

					if ( _position == 'left') {
						this.setAttribute( 'style', 'transform: translateX(-' + _scrollParallax + 'px); right: -' + _dimension[i] + 'px;' );
					}

					if ( _position == 'right') {
						this.setAttribute( 'style', 'transform: translateX(' + _scrollParallax + 'px); left: -' + _dimension[i] + 'px;' );
					}

					if ( _position == 'rotate-left') {
						this.setAttribute( 'style', 'transform: rotate(' + _scrollParallax + 'deg)' );
					}

					if ( _position == 'rotate-right') {
						this.setAttribute( 'style', 'transform: rotate(-' + _scrollParallax + 'deg)' );
					}

					if ( _position == 'opacity') {
						this.setAttribute( 'style', 'opacity: ' + (_percentage * 2) / 100 );
					}
				}
			}
		});
	}
}

export default initParallax;
