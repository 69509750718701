import $ from 'jquery';

const $window = $(window);
const $document = $(document);
const $body = $('body');
const $htmlBody = $('html,body');
const $bodyTag      = document.getElementsByTagName( 'body' )[0];
const $htmlBodyTag  = document.getElementsByTagName( 'body,html' )[0];
const breakpoints = {
	mobileSmall: window.matchMedia('(max-width: 374px)'),
	mobileMedium: window.matchMedia('(max-width: 575px)'),
	mobile: window.matchMedia('(max-width: 767px), screen and (max-width: 812px) and (orientation: landscape)'),
	tablet: window.matchMedia('(max-width: 1023px)'),
	desktop: window.matchMedia('(min-width: 1024px)'),
	smallDesktop: window.matchMedia('(max-width: 1199px)'),
	largeDesktop: window.matchMedia('(max-width: 1399px)'),
	smallDesktopUp: window.matchMedia('(min-width: 1200px)')
};

export {
	$window,
	$document,
	$body,
	breakpoints,
	$htmlBody,
	$bodyTag,
	$htmlBodyTag,
};
