import $ from 'jquery';

const $navBtn = $('.js-nav-btn');

const triggerNav = (e) => {
	e.preventDefault();

	const $btn = $(e.currentTarget);

	$btn.next('ul').toggleClass('is-opened');
};

$navBtn.on('click', triggerNav);
