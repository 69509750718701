import $ from 'jquery';
import { $window, $body } from '../utils/globals';
import className from '../utils/constant-names';

const _element   = '.js-slider-main';
const $container = $(_element);

if ( $container.length ) {
    let _timer         = null;
    let _timeout       = 5000;
    let _slideCount    = 0;
    let _allSlides     = parseInt($container.find( '.js__slide ' ).length, 10);
    let _arrTimeout    = [];
    let _sectionTop    = 0;
    let _sectionBottom = 0;
    let _scroll        = 0;

    $window.on( 'load resize orientationchange', function() {
        setHeaderAnimation();
    });

    $window.on( 'scroll', function() {
        _scroll = $window.scrollTop();

        headerAnimation();
    });

    /* Comment out to prevent auto advance */
    $window.on( 'load', function() {
        setTimeout(function() {
            slideTimeout();
        }, _timeout);
    });

    $container.find( '.js__prev' ).on( 'click', function( event ) {
        event.preventDefault();

        _slideCount = ( _slideCount - 1 >= 0 ) ? _slideCount - 1 : _allSlides;

        addSlide();
        nextSlide();
    });

    $container.find( '.js__next' ).on( 'click', function( event ) {
        event.preventDefault();

        _slideCount = ( _slideCount + 1 <= _allSlides ) ? _slideCount + 1 : 0;

        addSlide();
        nextSlide();
    });

    function slideTimeout() {
        let _desktopMobile = ( $window.width() >= 768 ) ? ':first-child' : ':last-child';

        if ( $container.find( 'video' + _desktopMobile ).length ) {
            clearTimeout( _timer );

            _allSlides = $container.find( '.js__slide' ).length - 1;

            $container.find( 'video' + _desktopMobile ).each( function( i ) {
                const $this = $(this);
                const _i    = i;
                const _duration = $($this).get(0).duration;

                _arrTimeout[i] = parseInt( ( _duration * 1000 ) + _timeout, 10 );
            });

            if ( _arrTimeout[0] == undefined || isNaN( _arrTimeout[0] ) ) {
                _timer = setTimeout(function() {
                    slideTimeout();
                }, 1200);
            } else {
                nextSlide();
            }
        }
    }

    function nextSlide() {
        let _desktopMobile = ( $window.width() >= 768 ) ? ':first-child' : ':last-child';

        if ( $container.find( 'video' + _desktopMobile ).length && _arrTimeout[ _slideCount ] != undefined ) {
            clearTimeout( _timer );

            $container.find( 'video' + _desktopMobile ).each( function( i ) {
                const $this  = $(this);
                const $video = $($this).get(0);

                if ( i == _slideCount ) {
                    $video.play();
                } else {
                    $video.pause();
                    $video.currentTime = 0;
                }
            });

            _timer = setTimeout(function() {
                _slideCount = ( _slideCount + 1 <= _allSlides ) ? _slideCount + 1 : 0;

                addSlide();
                nextSlide();

            }, _arrTimeout[ _slideCount ]);
        }
    }

    function addSlide() {
        const $active     = $container.find( '.js__slide' ).eq( _slideCount );
        const $parents    = $active.parents( _element );
        const _background = $active.find( '> *' ).data( 'background' );
        const $header     = $( '.js-header' );

        $parents.attr( 'data-background', _background );
        $header.attr( 'data-background', _background );
        $active.addClass( className.Active ).siblings().removeClass( className.Active );
    }

    function setHeaderAnimation() {
        const $section = $( '.section-slider-main' );

        _sectionTop    = parseInt( $section.offset().top, 10 );
        _sectionBottom = parseInt( _sectionTop + $section.innerHeight(), 10 );
    }

    function headerAnimation() {
        $body.toggleClass( className.HideHeader, _scroll > 10 && _scroll >= _sectionTop && _scroll <= _sectionBottom );
    }
}
