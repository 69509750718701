import $ from 'jquery';
const _element = 'gform_wrapper';
const $element = document.getElementsByClassName( _element )[0];

if ( typeof( $element ) != 'undefined' && $element != null ) {
	const _files = [
		'gforms_reset_css-css',
		'gforms_formsmain_css-css',
		'gforms_ready_class_css-css',
		'gforms_browsers_css-css',
		'gforms_stripe_frontend-css',
		'gform_basic-css',
		'gform_theme-css',
		'gform_theme_ie11-css',
	];

	for ( let i = 0; i < _files.length; i++ ) {
		const $id = document.getElementById( _files[ i ] );

		if ( typeof( $id ) != 'undefined' && $id != null ) {
			$id.remove();
		}
	}
}
