import $ from 'jquery';

const ScrollLocker = {
	$body: $('body'),
	scrollPosition: 0,
	enableScrollLock: function() {
		const $adminBar = $('#wpadminbar');
		this.scrollPosition = window.pageYOffset;

		if ( $adminBar.length ) {
			this.scrollPosition -= $adminBar.outerHeight();
		}

		this.$body.get()[0].style.overflow = 'hidden';
		this.$body.get()[0].style.position = 'fixed';
		this.$body.get()[0].style.top = `-${this.scrollPosition}px`;
		this.$body.get()[0].style.width = '100%';
	},
	disableScrollLock: function() {
		const $adminBar = $('#wpadminbar');

		if ( $adminBar.length ) {
			this.scrollPosition += $adminBar.outerHeight();
		}

		this.$body.get()[0].style.removeProperty('overflow');
		this.$body.get()[0].style.removeProperty('position');
		this.$body.get()[0].style.removeProperty('top');
		this.$body.get()[0].style.removeProperty('width');
		window.scrollTo(0, this.scrollPosition);
	}
};

export default ScrollLocker;
