/**
 * Set Cookie
 * 
 * @return {true or false}
 */
const setCookie = ( cname, cvalue, exdays ) => {
	const d = new Date();
	d.setTime(d.getTime() + (exdays*24*60*60*1000));

	let expires = "expires="+ d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export default setCookie;
