import $ from 'jquery';
import { $window, $body } from '../utils/globals';
import className from '../utils/constant-names';

let _isResize = false;
let _timer    = null;

$window.on( 'resize', function() {
    clearTimeout( _timer );

    if ( !$body.hasClass( className.IsResize ) && _isResize == false ) {

        _isResize = true;

        $body.addClass( className.IsResize );
    }

    _timer = setTimeout(function() {
        if ( $body.hasClass( className.IsResize ) ) {
            $body.removeClass( className.IsResize );
        }
    }, 1000);
});
