import $ from 'jquery';
import { $window, $html } from '../utils/globals';
import className from '../utils/constant-names';

const _element   = '.js-header'
const $container = $(_element);

if ( $container.length ) {
	$window.on( 'ready load resize orientationchange', function() {
		setHeaderHeight();
	});

	function setHeaderHeight() {
		const _height = parseInt($container.innerHeight(), 10) / 10;

		document.documentElement.style.setProperty('--header-height', `${_height}rem`);
	}
}

