export default {
	Hover:           'hover',
	Active:          'active',
	Open:            'open',
	IsTouch:         'is-touch',
	IsIos:           'is-ios',
	IsIE:            'is-ie',
	IsEdge:          'is-edge',
	IsScrollTop:     'is-scroll-top',
    IsResize:        'is-resize',
	ShowFixedHeader: 'show-fixed-header',
	ShowNavMain:     'show-nav-main',
	PageLoaded:      'page-loaded',
    HideHeader:      'hide-header',
};
