import scrollToSection from '../utils/scroll-to-section';
import $ from 'jquery';
import { $style } from '../utils/map-style';
import { $window } from '../utils/globals';

const _element   = '.section-locations-intro';
const $container = $(_element);

if ( $container.length ) {
	$container.each(function(index) {
		const $this 		   = $(this).find('.js__map');
        const $form            = $(this).find('.js__form');
		const _zoom            = $this.data('zoom');
		const _marker          = $this.data('marker');
		const _markerRetina    = $this.data('marker-retina');
        const _minDistance     = parseInt( $this.data('min-distance'), 10 );
		const _id              = _element.replace(/./, '') + '-' + index;
        const geocoder         = new google.maps.Geocoder();
		
		let map, myOptions, icon;
		let _markerWidth   = $this.data('marker-width');
		let _markerHeight  = $this.data('marker-height');
        let infowindow     = [];
		let marker         = [];
		let markerLatLng   = [];
		let markerZip      = [];
        let markerName     = [];
        let markerLink     = [];
		let bounds 	       = new google.maps.LatLngBounds();
		let _timer         = null;
        let center_lat_lng = false;

		$this.attr('id', _id);

		if(_markerRetina == true) {
			_markerWidth = parseInt(_markerWidth / 2, 10);
			_markerHeight = parseInt(_markerHeight / 2, 10);
		}

		if ( _marker != false ) {
			icon = new google.maps.MarkerImage(_marker, null, null, null, new google.maps.Size(_markerWidth,_markerHeight));
		} else {
			icon: false;
		}

		myOptions = {
			zoom: _zoom,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
            sensor: 'true',
            panControl: false,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            overviewMapControl: false,
            fullscreenControl: false,
			minZoom: 5,
			maxZoom: 17,
            styles: $style
		};

		map = new google.maps.Map(document.getElementById(_id), myOptions);

		/*
			- Set All Locations
		*/
		$('.js-block-location .js__map').each(function(i) {
			const $location = $(this);
			const _address  = $location.data('location').split(',');
			const _zip      = $location.data('city');
            const _name     = $location.data('name');
            const _link     = $location.data('link');

            infowindow[i] = new google.maps.InfoWindow({
                content: '<h6>' + _name + '</h6><p>' + _zip + '</p>'
            });

			marker[i] = new google.maps.Marker({
		  		position: new google.maps.LatLng(_address[0], _address[1]),
				map: map,
				icon: icon
			});

			markerLatLng[i] = _address[0] + ',' + _address[1];
			markerZip[i]    = _zip;
            markerName[i]   = _name;
            markerLink[i]   = _link;

			bounds.extend(marker[i].getPosition());
			map.fitBounds(bounds);

            marker[i].addListener('click', function() {
                window.open( markerLink[i], '_self' );
            });

            marker[i].addListener('mouseover', function() {
                $.each(marker, function( index, value ) {
                  infowindow[index].close();
                });

                infowindow[i].open(map, marker[i]);
            });

            marker[i].addListener('mouseout', function() {
                $.each(marker, function( index, value ) {
                  infowindow[index].close();
                });
            });
		});

        /*
            - Clear Entry
        */

        $container.on( 'click', '.js__clear-entry', function( event ) {
            event.preventDefault();
            const $this  = $(this);
            const $form  = $this.parents( '.js__form' );
            const $input = $form.find( 'input' );

            $input.val( '' );
            $form.removeClass( 'has-value' );
            $form.removeClass( 'has-error' );

            setTimeout(function() {
                $input.focus();

                setCenterMap( _zoom );
            }, 450);
        });

		/*
			- Filter By ZIP Code
		*/

		$container.find( 'form' ).on( 'submit', function( event ) {
			event.preventDefault();

			clearTimeout( _timer );

			bounds = new google.maps.LatLngBounds();

			const $this     = $(this);
			const _zipValue = $this.find( 'input' ).val();
			const _zipCode  = parseInt(_zipValue, 10);
            const $form     = $container.find( '.js__form' );

			let _hasFound = false;

			if ( _zipValue.length != 0 ) {
                $form.addClass( 'has-value' );

				$.each( markerZip, function( _index, _value ) {
					if ( _value.indexOf( _zipCode ) != -1 && _zipValue.length >= 4 ) {
						marker[_index].setVisible( true );
						bounds.extend(marker[_index].getPosition());

						_hasFound = true;
					} else {
						marker[_index].setVisible( false );
					}
				});

				if ( _hasFound == true ) {
					map.fitBounds(bounds);

					setCenterMarker( 17, 0.00312 );

					scrollToSection( $('[data-id="section-map"]') );
				} else {
                    $.each(markerLatLng, function( _index, _value ) {
                        let origonArr    = markerLatLng[_index].split( ',' );
                        let origin1      = new google.maps.LatLng( origonArr[0], origonArr[1] );
                        let destinationA = _zipValue;
                        let service      = new google.maps.DistanceMatrixService();

                        service.getDistanceMatrix({
                            origins: [origin1],
                            destinations: [destinationA],
                            travelMode: 'DRIVING',
                            },
                            function( response, status ) {
                                clearTimeout( _timer );

                                if ( response.rows[0].elements[0].status != 'NOT_FOUND' ) {
                                    let _distanceArr = response.rows[0].elements[0].distance.text.replace(',','').replace('.','').split( ' ' );
                                    let _distance    = parseInt(_distanceArr[0], 10);

                                    if ( _distance <= _minDistance ) {
                                        _hasFound = true;

                                        marker[_index].setVisible( true );
                                        bounds.extend(marker[_index].getPosition());
                                    } else {
                                        marker[_index].setVisible( false );
                                    }
                                }

                                _timer = setTimeout(function() {
                                    if ( _hasFound == true ) {
                                        $form.removeClass( 'has-error' );
                                        map.fitBounds(bounds);
                                        scrollToSection( $('[data-id="section-map"]') );
                                        setCenterFromZipCode( destinationA, 0.3, _zoom );
                                    } else {
                                        $form.addClass( 'has-error' );

                                        $.each(markerLatLng, function( _index, _value ) {
                                            marker[_index].setVisible( true );
                                            bounds.extend(marker[_index].getPosition());
                                        });

                                        map.fitBounds(bounds);
                                        scrollToSection( $('[data-id="section-map"]') );
                                        setCenterFromZipCode( destinationA, 0.3, _zoom );
                                    }
                                }, 1000);
                        });
                    });
                }
			} else {
                $form.removeClass( 'has-value' );

				$.each( markerZip, function( _index, _value ) {
					marker[_index].setVisible( true );
					bounds.extend(marker[_index].getPosition());
				});				

				map.fitBounds(bounds);
				setCenterMarker( _zoom, 0.3);
			}
		});

        function setCenterFromZipCode( address, _zoomValue, _zoom ) {
            geocoder.geocode( { 'address': address}, function(results, status) {
                if ( status == google.maps.GeocoderStatus.OK ) {
                    const _move     = ( $window.width() <= 767 ) ? 0 : _zoomValue;
                    const centerPos = {
                        lat: results[0].geometry.location.lat(),
                        lng: results[0].geometry.location.lng() - _move
                    }

                    map.setCenter( centerPos );
                    map.setZoom( _zoom );
                } else {
                    setCenterMarker( _zoom, 0.3);
                }
            });
        }

		function setCenterMarker( _zoomMap, _zoomValue ) {
			map.setZoom( _zoomMap );

			let _move         = ( $window.width() <= 767 ) ? 0 : _zoomValue;	
			let center_latLng = map.getCenter();
			let centerPos     = {
                lat: center_latLng.lat(),
                lng: center_latLng.lng() - _move
            };

            map.setCenter(centerPos);
		}

		function setCenterMap( _zoomMap ) {
			map.setZoom( _zoomMap );

            if ( center_lat_lng == false ) {
    			const _move         = ( $window.width() <= 767 ) ? 0 : 0.3;
    			const center_latLng = map.getCenter();

                center_lat_lng = {
                    lat: center_latLng.lat(),
                    lng: center_latLng.lng() - _move
                };
            }

            map.setCenter( center_lat_lng );
		}

		setTimeout(function() {
			setCenterMap( _zoom );
		}, 2000);
	});	
}
