import $ from 'jquery';
import { $window } from '../utils/globals';
import AOS from 'aos';

const _element   = '[data-aos]';
const $container = $(_element);

if ( $container.length ) {
	const _settings = {
		duration: 1200,
		once: true
	};

	$window.on( 'load', function() {
		AOS.init( _settings );
	});
}
