import $ from 'jquery';
import { $style } from '../utils/map-style';

const _element   = '.js-section-location-info';
const $container = $(_element);

if ( $container.length ) {
    $container.each( function( i ) {
        const $this          = $(this).find( '.js__map' );
        const _address       = $this.data('location').split(',');
        const _zoom          = $this.data('zoom');
        const _marker        = $this.data('marker');
        const _markerRetina  = $this.data('marker-retina');
        const _id            = _element.replace(/./, '') + '-' + i;
        
        let map, marker, myOptions, icon;
        let _markerWidth   = $this.data('marker-width');
        let _markerHeight  = $this.data('marker-height');

        $this.attr('id', _id);

        if ( _markerRetina == true ) {
            _markerWidth = parseInt(_markerWidth / 2, 10);
            _markerHeight = parseInt(_markerHeight / 2, 10);
        }

        icon = new google.maps.MarkerImage(_marker, null, null, null, new google.maps.Size(_markerWidth,_markerHeight));

        myOptions = {
            zoom: _zoom,
            center: new google.maps.LatLng(_address[0], _address[1]),
            disableDefaultUI: true,
            styles: $style
        };

        map = new google.maps.Map(document.getElementById(_id), myOptions);

        marker = new google.maps.Marker({
            position: new google.maps.LatLng(_address[0], _address[1]),
            map: map,
            icon: icon,
            clickable: false
        });       
    });
}
