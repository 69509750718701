import $ from 'jquery';

const $orderBtn = $('.btn--dropdown');

const openOrderDropdown = (e) => {
	e.preventDefault();

	const $dropdown = $('.btn--dropdown-list');

	$dropdown.slideToggle('fast');
    $orderBtn.toggleClass('is-open');
};

$orderBtn.on('click', openOrderDropdown);
